import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";

import "./index.css";

import Loading from "./components/app/Loading";
import * as serviceWorker from "./serviceWorker";

// lazy load the entire application
const Application = lazy(() =>
  import("./Root").then(({ Application }) => ({ default: Application }))
);
ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <Application />
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
