import React from "react";
import styled from "styled-components";

const Styled = styled.div`
  padding: 5rem;
  text-align: center;
  font-size: 1.5em;
  color: #888;
`;

interface Props {}

const Loading: React.FC<Props> = (props) => {
  return <Styled>Loading. Please wait...</Styled>;
};

export default Loading;
